<template>
  <q-form ref="editForm">
    <c-card title="달력 컴포넌트" height="700px" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="기본"
            name="col1"
            v-model="data.col1"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :disabled="true"
            label="disabled"
            name="col1"
            v-model="data.col1"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            label="필수값"
            name="col1"
            v-model="data.col1"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="LBLPERIOD"
            name="col2"
            v-model="data.col2"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            type="month"
            label="월"
            name="col3"
            v-model="data.col3"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            type="year"
            label="년"
            name="col4"
            v-model="data.col4"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :minuteStep="1"
            type="time"
            label="시분"
            name="col5"
            v-model="data.col5"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            type="time"
            label="시분초"
            name="col6"
            v-model="data.col6"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :minuteStep="1"
            type="datetime"
            label="일자 + 시분"
            name="col7"
            v-model="data.col7"
          />
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
export default {
  name: 'datepicker',
  data() {
    return {
      editable: true,
      data: {
        col1: null,
        col2: [],
        col3: null,
        col4: null,
        col5: null,
        col6: null,
        col7: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
